<template>
    <div class="shop-selection-group-goods">
        <!-- 主体 -->
        <CompContent title="添加选品">
            <!-- 头部搜索层 -->
            <template v-slot:operate>
                <Button type="primary" icon="ios-search" @click="onSearch">查询</Button>
                <Button type="success" icon="ios-refresh" @click="onReset">重置</Button>
                <Button @click="isDiaplayAdvanced = !isDiaplayAdvanced">
                    <Checkbox v-model="isDiaplayAdvanced" @click.stop></Checkbox>
                    <span>高级搜索</span>
                </Button>
                <Button type="info" @click="onBatchSync">批量同步到商家</Button>
                <Button type="primary" icon="md-add" @click="Route.jump('/shopselectiongoods', { c: $core.getUrlParam('c') })">从千帆供应链添加商品</Button>
            </template>
            <template>
                <div class="content-box">
                    <div class="search-box">
                        <div class="search-item">
                            <div class="title">搜索：</div>
                            <div class="elem-box">
                                <Input v-model.trim="search.keyword" placeholder="输入商品名称、商品ID" style="width: 250px"></Input>
                            </div>
                        </div>
                        <div class="search-item" v-if="isDiaplayAdvanced">
                            <div class="title">筛选条件：</div>
                            <div class="elem-box">
                                <Select v-model="search.status" placeholder="选择商品状态" filterable style="width:200px" transfer>
                                    <Option :value="0">下架</Option>
                                    <Option :value="1">上架</Option>
                                </Select>
                                <Select v-model="search.isFreeShipping" placeholder="选择配送状态" filterable style="width:200px" transfer>
                                    <Option :value="0">不包邮</Option>
                                    <Option :value="1">包邮</Option>
                                </Select>
                            </div>
                        </div>
                        <div class="search-item" v-if="isDiaplayAdvanced">
                            <div class="title">筛选区间：</div>
                            <div class="elem-box">
                                <ElemSection v-model="search.agreementPrice" title="协议价"></ElemSection>
                                <ElemSection v-model="search.guidePrice" title="指导价"></ElemSection>
                                <ElemSection v-model="search.activityPrice" title="营销价"></ElemSection>
                                <ElemSection v-model="search.activityRate" title="利润率"></ElemSection>
                            </div>
                        </div>
                    </div>

                    <ElemTable class="table" ref="elem_table" isSelector :columns="columns" :table-api="'/gateway/apps/o2o/api/o2o/platform/goods/listPlatformGoodsByPage?categoryId=' + $core.getUrlParam('c')" method="post" :processPageConfig="processPageConfig">
                        <template #operate="evt">
                            <Button size="small" type="info" @click="displaySyncConfig([evt.row.goodsId])" style="height:28px;padding:0 10px;margin:0 5px">同步到商家</Button>
                        </template>
                    </ElemTable>
                </div>
            </template>
        </CompContent>

        <CompModal ref="modal" :title="'商品同步到商家（' + platformGoodsIds.length + '个商品）'" @on-close="onCloseModal">
            <div class="modal-box">
                <div class="modal-item">
                    <p class="title">选择商家</p>
                    <ElemCheck v-model="merchant" column search :list="merchants"></ElemCheck>
                </div>
                <div class="modal-item">
                    <p class="title">选择分类</p>
                    <ElemCheck v-model="category" column search :list="categorys"></ElemCheck>
                </div>
            </div>
            <template #operate>
                <div class="operate-btns">
                    <div class="operate-btn highlight" @click="onSubmitSync(0)">确定，同步过的商品不再同步</div>
                    <div class="operate-btn" @click="onSubmitSync(1)">确定，同步过的商品覆盖更新</div>
                </div>
            </template>
        </CompModal>
    </div>
</template>

<script>
import Route from "../../../communitymobilization/entity/Route"
import CompModal from "../../../residentdatabase/components/CompModal.vue"
import CompTable from "../../../jointly/components/comp-table.vue"
import ElemCheck from "../../../customform/components/ElemCheck"
import ElemSection from "../../../customform/components/ElemSection.vue"
import CompContent from "../../../customform/components/CompContent.vue"
import Request from "../../../jointly/utils/request"
import ElemTable from "../../../communitymobilization/components/ElemTable.vue"

export default {
    components: { CompModal, CompTable, ElemCheck, ElemSection, CompContent, ElemTable },

    data() {
        return {
            search: {},
            Route: Route,
            columns: [
                {
                    title: "商品图片",
                    width: 120,
                    align: "center",
                    render: (h, p) => `<img src="${p.row.cover}" style="width:80px;height:80px;padding:5px" />`,
                },
                {
                    title: "商品名称",
                    key: "title",
                    width: 300,
                    align: "center",
                },
                {
                    title: "品牌",
                    key: "thirdCategoryName",
                    width: 150,
                    align: "center",
                },
                {
                    title: "市场价",
                    key: "goodsCount",
                    width: 150,
                    align: "center",
                    render: (h, p) => `<p>￥${(p.row.marketPrice / 100).toFixed(2)}</p>`,
                },
                {
                    title: "指导价",
                    key: "goodsCount",
                    width: 150,
                    align: "center",
                    render: (h, p) => `<p>￥${(p.row.guidePrice / 100).toFixed(2)}</p>`,
                },
                {
                    title: "协议价",
                    width: 150,
                    align: "center",
                    render: (h, p) => `<p>￥${(p.row.agreementPrice / 100).toFixed(2)}</p>`,
                },
                {
                    title: "利润率",
                    key: "goodsCount",
                    width: 150,
                    align: "center",
                    render: (h, p) => `<p>${String(p.row.activityRate) === "null" ? "-" : p.row.activityRate + "%"}</p>`,
                },
                {
                    title: "来源",
                    width: 150,
                    align: "center",
                    render: (h, p) => `<p>${{ 1: "千帆供应链" }[p.row.dataSource] || "其他"}</p>`,
                },
                {
                    title: "操作",
                    width: 150,
                    slot: "operate",
                    float: "right",
                },
            ],
            // 商家列表
            merchants: null,
            // 选择的商家
            merchant: null,
            // 分类列表
            categorys: null,
            // 选择的分类
            category: null,
            // 待同步商品
            platformGoodsIds: [],
            // 高级搜索
            isDiaplayAdvanced: false,
        }
    },

    watch: {
        merchant(v) {
            this.getCategorys(v)
        },
    },

    methods: {
        processPageConfig(data, body) {
            body.pageRo = {
                page: data.page,
                pageSize: data.pageSize,
            }
        },

        async displaySyncConfig(id) {
            if (!this.merchants) {
                var res = await Request.get("/gateway/apps/o2o/api/o2o/user/selectUserHasSupplier")

                if (!res || res.length === 0) {
                    return this.$Message.info("当前账号暂未绑定商家，请先前往绑定！")
                }

                var merchants = []

                res.forEach(v => {
                    merchants.push({ value: v.supplierId, name: v.supplierName })
                })

                this.merchants = merchants
            }

            this.platformGoodsIds = id
            this.$refs.modal.display()
        },

        getCategorys(s) {
            if (!s) return (this.categorys = null)

            Request.get("/gateway/apps/o2o/api/o2o/admin/getSupplierCategoryListBySupplierId", {
                oemCode: parent.vue.oemInfo.oemCode,
                supplierId: s,
            }).then(res => {
                this.categorys = res?.map?.(v => ({ value: v.catId, name: v.catName })) || null
            })
        },

        onSubmitSync(status) {
            if (!this.merchant) return this.$Message.error("请选择商家")
            if (!this.category) return this.$Message.error("请选择分类")

            Request.post(
                "/gateway/apps/o2o/api/o2o/platform/goods/batchSyncToSupplier",
                {
                    platformGoodsIds: this.platformGoodsIds,
                    supplierId: this.merchant, //店铺ID
                    catId: this.category, //分类ID
                    syncStatus: status, //同步状态，0：已存在的不同步；1：同步覆盖已存在的数据
                },
                {
                    json: true,
                }
            ).then(() => {
                this.$Message.success("同步成功")
                // 关闭弹窗
                this.$refs.modal.close()
            })
        },

        /**
         * 监听弹窗关闭事件
         */
        onCloseModal() {
            // 清空已选
            this.merchant = null
            this.category = null
        },

        /**
         * 批量同步
         */
        onBatchSync() {
            var s = this.$refs.elem_table.selecteds

            if (!s || s.length === 0) {
                return this.$Message.warning("请至少选择一个商品")
            }

            this.displaySyncConfig(s.map(v => v.goodsId))
        },

        onSearch() {
            this.$refs.elem_table.search(this.search)
        },

        onReset() {
            this.search = {}
            this.$refs.elem_table.reset()
        },
    },
}
</script>
<style lang="less">
.shop-selection-group-goods {
    .toolsbarRight {
        align-items: center;

        div,
        button {
            margin: 5px 0 5px 8px !important;
        }
    }

    .comp-table .search-box .search {
        width: 100%;
        padding-right: 0;

        > div {
            margin: initial !important;
        }
    }

    .search-item {
        padding: 8px 10px 8px 0;
        display: flex;
        width: 100%;
        border-bottom: 1px solid #f3f3f3;

        &:last-child {
            border-bottom: 0;
        }

        > .title {
            line-height: 32px;
            margin: 2px 10px 2px 0;
            flex-shrink: 0;
            font-size: 13px;
            color: #888;
        }

        .elem-box {
            flex-grow: 1;
            display: flex;
            flex-wrap: wrap;

            > div {
                margin: 2px 20px 2px 0;
            }

            .item {
                margin-right: 20px;

                > div {
                    margin-top: 4px;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .content-box {
        background: #f9f9f9;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .search-box {
            margin: 10px 0;
            padding: 0 10px;
            background: #fff;
            border-radius: 8px;
            flex-shrink: 0;

            .search-item {
                padding: 10px 10px 10px 0;
                display: flex;
                width: 100%;
                border-bottom: 1px solid #f3f3f3;

                &:last-child {
                    border-bottom: 0;
                }

                > .title {
                    line-height: 32px;
                    margin: 2px 10px 2px 10px;
                    flex-shrink: 0;
                    font-size: 13px;
                    color: #888;
                }

                .elem-box {
                    flex-grow: 1;
                    display: flex;
                    flex-wrap: wrap;

                    > div {
                        margin: 2px 20px 2px 0;
                    }

                    .item {
                        margin-right: 20px;

                        > div {
                            margin-top: 4px;

                            &:first-child {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }

        .table {
            margin: 0 0 10px 0;
            flex-grow: 1;
            overflow: hidden;
        }
    }
}

.modal-box {
    display: flex;
    height: 100%;

    .modal-item {
        width: 300px;
        padding: 20px;
        border-right: 1px solid #e3e3e3;
        overflow-y: auto;

        &:last-child {
            border-right: initial;
        }

        .title {
            margin-bottom: 5px;
            font-size: 16px;
        }
    }
}
</style>
